import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import ThemeLayout from "../../../layout/themeLayout";
import Button from "../../../component/Button/Button";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { challenges as ChallengersApi, challenges } from "../../../api/challenges";
import { ChallengersWidget } from "../../../Widgets/Challenges";
import Spinner from "../../../component/Spinner/Spinner";
import BackButton from "../../../component/BackButton/BackButton";
import CoverImage from "../../../component/Image/CoverImage";
import styles from "./challengers.module.css";
import { BANNER, PLACEHOLDER } from "../../../helpers/constant";
import Icon from "../../../component/Icon/Icon";
import RoundImage from "../../../component/Image/RoundImage";
import { formattedDate } from "../../../helpers/dates";
import { Link } from "react-router-dom";
import ParticipantModal from "../Modals/ParticipantModal";
import Video from "../../../component/Video/Video";

const Challengers = () => {
    const [challengers, setChallengers] = useState([]);
    const [challenge, setChallenge] = useState();
    const history = useHistory();
    const dispatch = useDispatch();
    const { challengeId } = useParams();
    const [pagination, setPagination] = useState({ current_page: 0 });
    const [selectedParticipant, setSelectedParticipant] = useState({});
    const [showParticipant, setShowParticipant] = useState(false);
    const getBadge = (c) => {
        switch (c) {
            case 1:
                return "gold";
            case 2:
                return "silver";
            case 3:
                return "bronze";
            default:
                return "";
        }
    };

    // challengers get func
    const getChallengers = useCallback((per_page = 12, page_no = 1) => {
        dispatch(
            ChallengersApi({
                param_path: challengeId + "/challengers",
                params: {
                    per_page,
                    page_no,
                },
            }),
        )
            .then((response) => {
                if (response.success) {
                    setPagination(response.pagination_data);
                    setChallengers((p) => p.concat(response.data));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const getChallenge = () => {
        dispatch(challenges({ param_path: challengeId }))
            .then((response) => {
                if (response.success) {
                    setChallenge(response.data);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        getChallengers();
        getChallenge();
    }, [dispatch]);

    const challengerClicked = (challenger) => {
        setSelectedParticipant(challenger);
        setShowParticipant(true);
    };

    const renderChallengers = () => {
        const renderChallengerItems = () => {
            return (
                challengers
                    // ?.filter((c, i) => c.rank !== 1)
                    ?.map((c, i) => (
                        <div
                            key={i}
                            className="col-lg-4 mt-3"
                            onClick={() => {
                                challengerClicked(c);
                            }}
                        >
                            <ChallengersWidget height={`250px`} post={c.post} challenger_id={c.challenger_id} votable={challenge?.status === "voting"} showButton={!showParticipant} />
                        </div>
                    ))
            );
        };

        const onEndReach = () => {
            getChallengers(12, pagination.current_page + 1);
        };

        return (
            <InfiniteScroll
                pageStart={0}
                loadMore={onEndReach}
                hasMore={pagination?.current_page < pagination?.last_page}
                loader={<Spinner key="Loadmore" type="LoadMore" />}
                threshold={50}
                useWindow={true}
            >
                <div className="row">{renderChallengerItems()}</div>
            </InfiniteScroll>
        );
    };

    const renderRunnersUp = () => {
        return challengers
            ?.filter((c, i) => (c.rank == 2 || c.rank == 3))
            ?.map((c, i) => (
                <div
                    key={i}
                    className="col-lg-4 mt-3"
                    onClick={() => {
                        challengerClicked(c);
                    }}
                >
                    <div className="text-center p-2 rounded mb-2" style={{ background: "#394654" }}>
                        <div className="runner-up-award">
                            <img src={`/public/images/resources/winner-badge-${c?.rank == 2 ? "silver" : "bronze"}.png` || PLACEHOLDER} alt="winner" style={{ width: "40px", height: "50px" }} />
                        </div>
                        <div className="runner-up-detail">
                            <RoundImage className="runnerup-img mr-1" size="20px" src={c?.post?.created_by?.profile_pic || PLACEHOLDER} />
                            <Link className="runnerup-name" to={`/profile/${c?.post?.created_by?.id}`}>
                                <span className="mb-0 text-capitalize text-truncate font-italic mr-1">{c?.post?.created_by?.name}</span>
                            </Link>
                            <div className="challenge-link">
                                <small
                                    className="font-italic cursor-pointer badge badge-light"
                                    onClick={() => {
                                        challengerClicked(c);
                                    }}
                                >
                                    {" "}
                                    View Detail <Icon name="long-arrow-right" />
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="center-ball-separator-hr"></div>
                </div>
            ));
    };
    const winnerScenario = () => {
        return (
            <div className="text-center winner-row ">
                <div className="winner-medal  position-relative mt-3">
                    <div className={`${styles["quiz-medal-lg"]}`}>
                        <div className={`${styles["winner-gold-award"]}`}>
                            <img src="/public/images/resources/winner-badge-gold.png" alt="winner" />
                        </div>
                    </div>
                </div>
                <div className="challenger">
                    <h2 className="mb-2" style={{ color: "#f5934b" }}>
                        Congratulations
                    </h2>
                    {challengers?.find(c => c.rank === 1) && (
                        <>
                        <RoundImage className={`mr-2`} size="30px" src={challengers?.find(c => c.rank === 1)?.post?.created_by?.profile_pic || PLACEHOLDER} />
                        <Link to={`/profile/${challengers?.find(c => c.rank === 1)?.post?.created_by?.id}`}>
                            <span className="mb-0 text-capitalize text-truncate font-italic mr-1">{challengers?.find(c => c.rank === 1)?.post?.created_by?.name}</span>
                        </Link>
                        </>
                    )}
                    <span>for the victory</span>
                    <div className="challenge-link">
                        <small
                            className="font-italic cursor-pointer badge badge-light"
                            onClick={() => {
                                challengerClicked(challengers?.find(c => c.rank === 1) && ( challengers?.find(c => c.rank === 1) ) );
                            }}
                        >
                            {" "}
                            View Detail <Icon name="long-arrow-right" />
                        </small>
                    </div>
                </div>
                {challengers?.filter((c, i) => c.rank !== 1)?.length > 0 && (
                    <>
                        <div className={`${styles["content-separator"]} content-separator`}>Runners Up</div>
                        <div className="runners-up row justify-content-center">{renderRunnersUp()}</div>
                    </>
                )}
                <div className="challengers-count my-2">
                    <div className="top-participant my-2">
                        {challenge.top_participants.map((p) => (
                            <Link key={p.created_by.id} to={`/profile/${p.created_by.id}`} title={p.created_by.name}>
                                <RoundImage className="mr-n2" alt={p.created_by.name} size="30px" src={p.created_by.profile_pic || PLACEHOLDER} />
                            </Link>
                        ))}
                    </div>
                    {`${challenge.challengers_count} Challenger${challenge.challengers_count > 1 ? "s" : ""}`} Participated.
                </div>
            </div>
        );
    };
    const inVotingState = () => {
        return (
            <div className="text-center text-white mt-3">
                <div className="prize-badge">
                    <img src="/public/images/resources/dollar-coin-icon.png" alt="dollar" />
                </div>
                <div className="anouncement-content">
                {challenge?.is_win_amount == 1 && (
                    <div>
                        <h5 className="mt-3" style={{ color: "#fff" }}>
                            Prize money:
                        </h5>
                        <div className="center-ball-separator-hr"></div>
                        <span className="" style={{ fontSize: "30px" }}>
                            {challenge?.win_amount??0}
                        </span>
                    </div>
                )}
                    <div className="center-ball-separator-hr"></div>
                    <h4 style={{ color: "#f5934b" }}>Submissions closed</h4>
                </div>

                <div className="center-ball-separator-hr"></div>
                <div className="row">
                    <div className="col-lg-4 mt-3">
                        <div className="text-center p-2 rounded mb-2" style={{ background: "#394654" }}>
                            <div className="">
                                <Icon className="" name="ui-calendar" style={{ fontSize: "30px", color: "#fbcf5d" }} />
                            </div>
                            <div className="mt-1">
                                <b className="d-block">Registration &amp; submission:</b> {moment(challenge?.registration_start).format("MMM-DD")} →{" "}
                                {moment(challenge?.registration_end).format("MMM-DD")}
                            </div>
                        </div>
                        <div className="center-ball-separator-hr"></div>
                    </div>

                    <div className="col-lg-4 mt-3">
                        <div className="text-center p-2 rounded mb-2" style={{ background: "#394654" }}>
                            <div className="">
                                <Icon className="" name="stamp" style={{ fontSize: "30px", color: "#fbcf5d" }} />
                            </div>
                            <div className="mt-1">
                                <b className="d-block">Voting:</b> {moment(challenge?.voting_start).format("MMM-DD")} → {moment(challenge?.voting_end).format("MMM-DD")}
                            </div>
                        </div>
                        <div className="center-ball-separator-hr"></div>
                    </div>

                    <div className="col-lg-4 mt-3">
                        <div className="text-center p-2 rounded mb-2" style={{ background: "#394654" }}>
                            <div className="">
                                <Icon className="" name="win-trophy" style={{ fontSize: "30px", color: "#fbcf5d" }} />
                            </div>
                            <div className="mt-1">
                                <b className="d-block">Winner announced:</b> {moment(challenge?.win_date).format("MMM-DD")}
                            </div>
                        </div>
                        <div className="center-ball-separator-hr"></div>
                    </div>
                </div>
                <div className="challengers-count mt-2">
                    <div className="top-participant">
                        {challenge.top_participants.map((p) => (
                            <Link key={p.created_by.id} to={`/profile/${p.created_by.id}`} title={p.created_by.name}>
                                <RoundImage className="mr-n2" alt={p.created_by.name} size="30px" src={p.created_by.profile_pic || PLACEHOLDER} />
                            </Link>
                        ))}
                    </div>
                    {`${challenge.challengers_count} Challenger${challenge.challengers_count > 1 ? "s" : ""}`} Participated.
                </div>
            </div>
        );
    };
    return (
        <div>
            <ThemeLayout>
                <div className="gap">
                    <div className="container">
                        {!!selectedParticipant.challenger_id && (
                            <ParticipantModal
                                className="full-width"
                                onClose={() => {
                                    setShowParticipant(false);
                                }}
                                show={showParticipant}
                                participant={selectedParticipant?.post}
                                top={{
                                    num: selectedParticipant?.rank,
                                    badge: getBadge(selectedParticipant?.rank),
                                    is_badge: !!getBadge(selectedParticipant?.rank),
                                }}
                            />
                        )}

                        <div
                            className="row remove-ext30"
                            // style={{ background: "#32c0cc14" }}
                        >
                            <div className="col-lg-12 p-0">
                                <BackButton />
                            </div>
                            <div className="col-lg-7 p-0" style={{ background: "#2c3e50", color: "#fff" }}>
                                {challenge?.status == "completed" && winnerScenario()}
                                {challenge?.status == "voting" && inVotingState()}
                            </div>
                            <div className="col-lg-5 p-md-0">
                                <div className="bg-light p-3">
                                    <div className="d-flex">
                                        <div className="d-item flex-fill">
                                            <div className="icon-section">
                                                <Icon name="trophy" style={{ fontSize: "50px" }} />
                                            </div>
                                            {/* <hr className="center-ball-separator-hr" /> */}
                                            <div className="challenge-title">
                                                <h3 className="text-capitalize text-lg-truncate" title={challenge?.title}>
                                                    {challenge?.title}
                                                </h3>
                                                <p>{challenge?.description}</p>
                                            </div>
                                        </div>
                                        <div className="d-item">
                                            <div>
                                                <small className="mr-1">Created by:</small>
                                                <RoundImage className="mr-1" size={`20px`} src={challenge?.created_by?.profile_pic || PLACEHOLDER} />
                                                <Link to={`/profile/${challengers[0]?.post?.created_by?.id}`}>
                                                    <span className="mb-0 text-capitalize text-truncate font-italic mr-1">{challenge?.created_by?.name}</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    {challenge && (
                                        <Video
                                            src={challenge?.post?.uri}
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                    )}
                                    <div className={`${styles["description"]} ${styles["scrolable-div"]}`} style={{ height: "230px" }}>
                                        <div className="challenge-submission mt-2">
                                            <h4>Instructions</h4>
                                            <p className="para mt-2">{challenge?.instructions}</p>
                                            <p className="para mb-0">Please, just make sure that:</p>
                                            <p className="para mb-0">* The source file is shared with us, your submission is related to and created for this Challenge, it's unique and your own</p>
                                            <p className="para mb-0">* You only make one submission per challenge</p>
                                        </div>
                                        <div className="challenge-deadline mt-2">
                                            <h4>Deadline</h4>
                                            <p className="para mb-0">
                                                <b>Registration &amp; submission:</b> {moment(challenge?.registration_start).format("MMM-DD")} → {moment(challenge?.registration_end).format("MMM-DD")}
                                            </p>
                                            <p className="para mb-0">
                                                <b>Voting:</b> {moment(challenge?.voting_start).format("MMM-DD")} → {moment(challenge?.voting_end).format("MMM-DD")}
                                            </p>
                                            <p className="para mb-0">
                                                <b>Winner announced:</b> {moment(challenge?.win_date).format("MMM-DD")}!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {(challenge?.status == "voting" || challenge?.status == "completed") && challenge?.challengers_count > 0 && (
                                <div className="col-12 p-0">
                                    <h5 className="congrats_title mb-2 font-weight-bold mt-5">Participant Videos</h5>
                                </div>
                            )}
                        </div>
                        {(challenge?.status == "voting" || challenge?.status == "completed") && challenge?.challengers_count > 0 && renderChallengers()}
                        {challenge?.challengers_count == 0 && <div className="col-12 text-center">No challengers yet</div>}
                    </div>
                </div>
            </ThemeLayout>
        </div>
    );
};
export default Challengers;
