import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ThemeLayout from "../../../layout/themeLayout";
import Joi from "joi";
import { getFormData } from "helpers/utility";
import Button from "component/Button/Button";
import Input from "component/Input/Input";
import Progress from "../../../component/Progress/Progress";
import { useHistory } from "react-router";
import Video from "../../../component/Video/Video";
import styles from "./acceptchallenge.module.css";
import { Link, useParams } from "react-router-dom";
import { challenges as ChallengersApi, challenges as ChallengeApi } from "../../../api/challenges";
import { createChallenge as participate } from "api/challenges";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { setErrorMessages, validateRequired } from "../../../helpers/validationMessages";
import RoundImage from "../../../component/Image/RoundImage";
import { PLACEHOLDER } from "../../../helpers/constant";
import BackButton from "../../../component/BackButton/BackButton";
import CoverImage from "../../../component/Image/CoverImage";
import Error from "../../../component/Error/Error";
import { isAllowedVideoFormat } from "../../../helpers/utility";
import { ChallengersWidget, ChallengersCardWidget } from "../../../Widgets/Challenges";
import InfiniteScroll from "react-infinite-scroller";
import Spinner from "../../../component/Spinner/Spinner";
import ParticipantModal from "../Modals/ParticipantModal";

const AcceptChallenge = () => {
    const user = useSelector((store) => store.user.user);
    const [challengers, setChallengers] = useState([]);
    const [challenge, setChallenge] = useState(null);
    const [errors, setErrors] = useState({});
    const history = useHistory();
    const dispatch = useDispatch();
    const { challengeId } = useParams();
    const fileUploadRef = useRef();
    const [uploadedVideo, setVideo] = useState();
    const [thumbnail, setThumbnailImage] = useState();
    const formRef = useRef();
    const postBtn = useRef();
    const progressRef = useRef();
    const [pagination, setPagination] = useState({ current_page: 0 });
    const [selectedParticipant, setSelectedParticipant] = useState({});
    const [showParticipant, setShowParticipant] = useState(false);
    const auth_user = useSelector((state) => state.user.user);

    const getBadge = (c) => {
        switch (c) {
            case 1:
                return "gold";
            case 2:
                return "silver";
            case 3:
                return "bronze";
            default:
                return "";
        }
    };

    const onSubmitForm = async (e) => {
        e.preventDefault();

        const formData = new FormData(formRef.current);
        const params = {
            data: {
                title: formData.get("title"),
                description: formData.get("description"),
                video: uploadedVideo,
                content: formData.get("description"),
                region_id: 1,
                is_public: 1,
                is_comment_allowed: 1,
                thumbnail: thumbnail,
            },
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
                progressRef.current?.setProgress((progressEvent.loaded / progressEvent.total) * 100);
            },
        };
        const { error } = newChallengerSchema.validate(params.data, {
            abortEarly: false,
        });

        if (error) {
            const t = setErrorMessages(error);

            return setErrors(t);
        }

        params.data = getFormData(params.data);

        postBtn.current.showLoader(true);
        try {
            const response = await dispatch(
                participate({
                    data: params.data,
                    param_path: challengeId + "/challengers",
                }),
            );

            if (response.success) {
                toast.success(response.message);
                setShow(false);
                getChallenge();
                // history.replace("/");
            }
        } catch (e) {
            const t = setErrorMessages(e, true);

            setErrors(t);
        }
        progressRef.current?.setProgress(0);
        postBtn.current?.showLoader(false);
    };
    const onThumbnailUpload = (e) => {
        setThumbnailImage(e.target.files[0]);
    };
    const openFileUpload = () => fileUploadRef.current?.click();
    const onFileUpload = (e) => {
        const videoFile = e.target.files[0];

        if (!isAllowedVideoFormat(videoFile.name)) {
            return setErrors({
                video: "Invalid video format",
            });
        }

        const video = document.createElement("video");
        video.src = URL.createObjectURL(videoFile);

        video.addEventListener(
            "loadeddata",
            function () {
                if (video.videoWidth < 0) {
                    return setErrors({
                        video: "Video Width out of bound",
                    });
                }

                if (video.videoHeight < 0) {
                    return setErrors({
                        video: "Video height out of bound",
                    });
                }

                if (video.duration > 600) {
                    return setErrors({
                        video: "Video length exceeds the time limit",
                    });
                }

                return setVideo(videoFile);
            },
            false,
        );

        video.addEventListener(
            "loadedmetadata",
            function () {
                if (video.videoWidth < 0) {
                    return setErrors({
                        video: "Video Width out of bound",
                    });
                }

                if (video.videoHeight < 0) {
                    return setErrors({
                        video: "Video height out of bound",
                    });
                }

                if (video.duration > 600) {
                    return setErrors({
                        video: "Video length exceeds the time limit",
                    });
                }

                return setVideo(videoFile);
            },
            false,
        );
    };

    const challengerClicked = (challenger) => {
        setSelectedParticipant(challenger);
        setShowParticipant(true);
    };

    const renderLinkOrStatus = () => {
        if (challenge?.status == "submission") {
            return !challenge?.has_participated ? <Link onClick={() => setShow(true)}>Participate</Link> : <>Participated</>;
        }
    };

    const renderChallengersCard = () => {
        const renderChallengerItems = () => {
            return (
                challengers
                    // ?.filter((c, i) => c.rank !== 1)
                    ?.map((c, i) => (
                        <ChallengersCardWidget post={c.post} challenger_id={c.challenger_id}  onClick={() => { challengerClicked(c); }} key={i} />
                    ))
            );
        };

        const onEndReach = () => {
            getChallengers(12, pagination.current_page + 1);
        };

        return (
            <InfiniteScroll
                pageStart={0}
                loadMore={onEndReach}
                hasMore={pagination?.current_page < pagination?.last_page}
                loader={<Spinner key="Loadmore" type="LoadMore" />}
                threshold={50}
                useWindow={true}
                className="row merged-10"
            >
                {renderChallengerItems()}
            </InfiniteScroll>
        );
    };

    // challengers get func
    const getChallengers = useCallback((per_page = 12, page_no = 1) => {
        dispatch(
            ChallengersApi({
                param_path: challengeId + "/challengers",
                params: {
                    per_page,
                    page_no,
                },
            }),
        )
            .then((response) => {
                if (response.success) {
                    setPagination(response.pagination_data);
                    setChallengers((p) => p.concat(response.data));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    // challenge get func
    const getChallenge = () => {
        dispatch(
            ChallengeApi({
                param_path: challengeId,
            }),
        )
            .then((response) => {
                if (response.success) {
                    setChallenge(response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getChallenge();
        getChallengers();
    }, []);

    // modal states and variables
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // modal body
    const renderBody = () => {
        const uploaded = (
            <div className="col-lg-5" style={{ padding: 0, position: "relative" }}>
                <Button
                    type="link"
                    style={{
                        zIndex: 1,
                        position: "absolute",
                        right: "-1px",
                        top: "22px",
                        padding: 0,
                    }}
                    onClick={() => setVideo(null)}
                >
                    <small
                        style={{
                            background: "#17a2b8",
                            color: "white",
                            padding: "3px",
                            fontSize: "10px",
                        }}
                    >
                        remove
                    </small>
                </Button>
                <div style={{ marginTop: "1.5rem" }}>{uploadedVideo && <Video src={URL.createObjectURL(uploadedVideo)} style={{ width: "100%", height: "250px", borderRadius: "10px" }} />}</div>
            </div>
        );
        const notUploaded = (
            <div
                onClick={openFileUpload}
                className="col-lg-5 video-file"
                style={{
                    backgroundColor: "#f0f5ff",
                    padding: "196px 0px 210px",
                    textAlign: "center",
                }}
            >
                {errors["video"] && <Error error={errors["video"]} />}
                <i className="icofont-video" style={{ color: "#1d3554", fontSize: 100 }}></i>
                <h3
                    style={{
                        color: "#1c1b1b",
                        fontSize: 15,
                        fontWeight: 500,
                        marginTop: 47,
                        marginBottom: 3,
                    }}
                >
                    Select video files to upload{" "}
                </h3>
                <span style={{ color: "#6e6f70", fontSize: 16 }}></span>
                <p>
                    {" "}
                    * MP4, WebM or Mov
                    <br />
                    * recommended resolution is 1280x720 or 720x1280
                    <br />* Up to 60 seconds
                </p>
                <input onChange={onFileUpload} accept=".mp4,.webm,.mov" ref={fileUploadRef} type="file" style={{ display: "none" }} />
            </div>
        );

        return (
            <div className="row m-0">
                {uploadedVideo && uploaded}
                {!uploadedVideo && notUploaded}
                <div className="col-lg-7 pr-0">
                    <div className={`${styles["main-wraper"]} main-wraper`}>
                        <div className="feedback-form">
                            <form ref={formRef} className="mt-4" onSubmit={onSubmitForm}>
                                <fieldset className="row">
                                    <div className="mb-4 col-lg-12">
                                        <div className="form-group1 mt-30">
                                            {thumbnail ? (
                                                <div
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                >
                                                    <Button
                                                        type="link"
                                                        style={{
                                                            position: "absolute",
                                                            right: -6,
                                                            top: -3,
                                                        }}
                                                        onClick={() => setThumbnailImage(null)}
                                                    >
                                                        <small
                                                            style={{
                                                                background: "#17a2b8",
                                                                color: "white",
                                                                padding: "3px",
                                                                fontSize: "10px",
                                                            }}
                                                        >
                                                            remove
                                                        </small>
                                                        {/* <i className="icofont-close-circled" /> */}
                                                    </Button>
                                                    <CoverImage
                                                        bgposition="center"
                                                        bgrepeat="no-repeat"
                                                        bgsize="contain"
                                                        bgsrc={`url(${URL.createObjectURL(thumbnail)})`}
                                                        width="100%"
                                                        height="250px"
                                                    />
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="image-upload-wrap">
                                                        <input onChange={onThumbnailUpload} type="file" accept="image/*" className="file-upload-input" />

                                                        <div className="drag-text">
                                                            <i className="icofont-cloud-upload"></i>
                                                            <h4>Select Video banner</h4>
                                                        </div>
                                                    </div>
                                                    {errors["thumbnail"] && <Error error={errors["thumbnail"]} className="mt-2" />}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mb-4 col-lg-12">
                                        <h4 className="main-title">Details</h4>
                                        <Input name="title" styleType="floating" placeholder="Title" />
                                        {errors["title"] && <Error error={errors["title"]} />}
                                    </div>

                                    <div className="col-lg-12">
                                        <Input name="description" styleType="floating-area" placeholder="Description" />
                                        {errors["description"] && <Error error={errors["description"]} />}
                                    </div>

                                    <div className="mb-0 col-lg-12 text-center">
                                        <Button ref={postBtn} htmlType="submit" type="button" className="form__button">
                                            Participate
                                        </Button>
                                        <Progress style={{ margin: "15px" }} ref={progressRef} />
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div>
            <ThemeLayout>
                <Modal size="xl" id="submit-modal" show={show} onHide={handleClose} backdrop="static">
                    <Modal.Header className="p-3 border-bottom">
                        <h4 className="modal-title">Accept Challenge </h4>
                        <Button className="close" type="link" onClick={handleClose}>
                            x
                        </Button>
                    </Modal.Header>
                    <Modal.Body>{renderBody()}</Modal.Body>
                </Modal>

                <div className="gap">
                    <div className="container">
                        {!!selectedParticipant.challenger_id && (
                            <ParticipantModal
                                className="full-width"
                                onClose={() => {
                                    setShowParticipant(false);
                                }}
                                show={showParticipant}
                                participant={selectedParticipant?.post}
                                top={{
                                    num: selectedParticipant?.rank,
                                    badge: getBadge(selectedParticipant?.rank),
                                    is_badge: !!getBadge(selectedParticipant?.rank),
                                }}
                            />
                        )}

                        {challenge && (
                            <div className="row mt-2">
                                <BackButton />
                                <div id="page-contents" className="mt-3">
                                    <div className="row remove-ext30 mb-5">
                                        <div className="col-md-6 px-lg-0">
                                            <div className="main-wraper top-renker h-100">
                                                <div className="main-title">
                                                    {challenge.title}
                                                    {challenge?.created_by?.id !== auth_user.id && (
                                                        <span
                                                            className="rate-result"
                                                            style={{
                                                                background: "#fec42d none repeat scroll 0 0",
                                                                borderRadius: "30px",
                                                                bottom: "15px",
                                                                color: "#fff",
                                                                fontSize: "11px",
                                                                left: "15px",
                                                                padding: "2px 10px",
                                                                float: "right",
                                                            }}
                                                        >{renderLinkOrStatus()}</span>
                                                    )}    
                                                </div>
                                                <div className="group-avatar">
                                                    <Video
                                                        src={challenge?.post.uri}
                                                        form
                                                        style={{
                                                            width: "100%",
                                                            height: "250px",
                                                            borderRadius: "10px",
                                                            backgroundColor: "#000000"
                                                        }}
                                                    />
                                                    <figure className="group-dp">
                                                        <Link to={`/profile/${challenge?.post?.created_by.id}`}>
                                                            <RoundImage size="100px" src={challenge?.post?.created_by?.profile_pic || PLACEHOLDER} alt={challenge?.created_by?.name} />
                                                        </Link>
                                                </figure>
                                                </div>

                                                <div className="grp-info about p-0">
                                                    <h4 className="mb-0"><Link to={`/profile/${challenge?.post?.created_by.id}`}>{challenge?.created_by?.name}</Link> <span>@{challenge?.created_by?.username}</span></h4>
                                                    <ul className="joined-info">
                                                        <li><span>Joined:</span> {moment(challenge?.post?.created_by?.created_at).format("MMM, YYYY")}</li>
                                                        <li><span>Follow:</span> {challenge?.post?.created_by?.no_followers}</li>
                                                        <li><span>Followers:</span> {challenge?.post?.created_by?.no_following}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pr-lg-0">
                                            <div className="main-wraper top-renker h-100">
                                                <div className="main-title">About Challenge</div>
                                                <div className="challenge-submission">
                                                    <h4>Instructions</h4>
                                                    <p className="pb-0 mb-0">{challenge?.instructions}</p>
                                                    <p className="pb-0 mb-0">Please, just make sure that:</p>
                                                    <ul>
                                                        <li className="pb-0 mb-0">The source file is shared with us, your submission is related to and created for this Challenge, it's unique and your own</li>
                                                        <li className="pb-0 mb-0">You only make one submission per challenge</li>
                                                    </ul>
                                                </div>
                                                <div className="challenge-deadline">
                                                    <h4>Deadline</h4>
                                                    {/* <p className="para mt-2">
                                                        The winner will have their best work featured newsletter and shared with 1,000,000+ professionals from around the world including employees from companies
                                                        such as Google, Airbnb, and Facebook. The winner will also be invited to join the community
                                                    </p> */}
                                                    <p className="pb-0 mb-0">
                                                        <b>Registration &amp; submission:</b> {moment(challenge.registration_start).format("MMM-DD")} → {moment(challenge.registration_end).format("MMM-DD")}
                                                    </p>
                                                    <p className="pb-0 mb-0">
                                                        <b>Voting:</b> {moment(challenge.voting_start).format("MMM-DD")} → {moment(challenge.voting_end).format("MMM-DD")}
                                                    </p>
                                                    <p className="pb-0 mb-0">
                                                        <b>Winner announced:</b> {moment(challenge.win_date).format("MMM-DD")}!
                                                    </p>
                                                    {/* <p className="para">Read the rules and use the inspiration below as a starting point. Good luck!</p> */}
                                                </div>
                                                <div className="challenge-desc mt-3">
                                                    <h4>Description</h4>
                                                    <p className="pb-0 mb-0">{challenge.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* participants */}
                                    <div>
                                        <h3 className="main-title">Participants</h3>
                                        { renderChallengersCard() }
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ThemeLayout>
        </div>
    );
};
const newChallengerSchema = Joi.object({
    content: Joi.string(),
    title: Joi.string()
        .required()
        .messages({
            "string.empty": validateRequired("Title"),
        }),
    is_public: Joi.number().optional(),
    is_comment_allowed: Joi.number().optional(),
    region_id: Joi.number().optional(),
    description: Joi.string()
        .required()
        .messages({
            "string.empty": validateRequired("Description"),
        }),
    video: Joi.object()
        .required()
        .messages({
            "any.required": validateRequired("Video"),
            "object.base": validateRequired("Video"),
        }),
    thumbnail: Joi.object()
        .required()
        .messages({
            "any.required": validateRequired("Thumbnail"),
            "object.base": validateRequired("Thumbnail"),
        }),
});

export default AcceptChallenge;
